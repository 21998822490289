import styles from './WhySaatva.module.scss'
import { SvgSustainability, SvgQuality, SvgWithoutMarkups } from './Svgs'

const reasons = [
    {
        Svg: SvgWithoutMarkups,
        heading: 'Premium quality without traditional retail markups',
        description: `We cut out the middlemen so you get the highest quality at the best possible value. To get the same quality elsewhere, you'd have to pay way more.`,
        link: 'https://pages.saatva.com/pricing'
    },
    {
        Svg: SvgQuality,
        heading: 'Made to order, never stuffed in a box',
        description: `Every Saatva mattress is assembled and handcrafted when you order it.  Unlike other internet mattresses, we'd never dream of compressing our premium beds into a small box. Instead, we deliver it factory-fresh to your room for free.`,
        link: 'https://pages.saatva.com/saatva-vs-bed-in-a-box'
    },
    {
        Svg: SvgSustainability,
        heading: 'Sustainability is at the core of our brand',
        description: 'From responsibly sourced materials to our domestic manufacturing & delivery process that lowers our carbon footprint, our eco-friendly ethos has been at the core of our brand from day one.',
        link: 'https://pages.saatva.com/green-initiatives'
    }
]

const WhySaatva = () => {
    return (
        <section className={`${styles.whySaatva} section`}>
            <div className="container">
                <h2 className={`${styles.whySaatvaTitle} t-heading2 u-textCenter u-marginBottom--2xl`}>
                    Why Saatva?
                </h2>
                <div className="row">
                    {reasons.map(({ Svg, heading, description, link }) => (
                        <div key={link} className={`${styles.tile} col col--xs-12 col--md-4`}
                        >
                            <div className={`row ${styles.row}`}>
                                <div className="col col--xs-12">
                                    <div className={styles.headingWrapper}>
                                        <div className={styles.iconWrapper}>
                                            <Svg className={styles.svg} />
                                        </div>
                                        <a href={link} data-selector={`heading-cta-${heading}`} className={`t-heading3 ${styles.title}`}>
                                            {heading}
                                        </a>
                                    </div>
                                    <div className={styles.description}>
                                        {description}
                                        <br />
                                        <a href={link} className={styles.cta} data-selector={`learn-more-cta-${heading}`}>Learn more</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default WhySaatva
